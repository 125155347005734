<template>
  <div>
    <form
      v-on:submit.prevent="submitAccount"
      id="account-form"
      class="new-account-form"
    >
      <div class="t-navy">
        <h3>{{ $t('content.new_bank_account') }}</h3>
        <v-row>
          <v-col md="5">{{ $t('content.routing_number') }}</v-col>
          <v-col md="7">
            <BaseFormGroup name="Routing Number" :validator="$v.accountForm.RoutingNumber">
              <template slot-scope="{ attrs }">
                <v-text-field
                  v-bind="attrs"
                  outlined
                  dense
                  v-model="accountForm.RoutingNumber"
                  :placeholder="`${$t('content.routing_number')}...`"
                  single-line
                  class="rounded-sm  mt-2"
                  clearable
                  background-color="#fff"
                  @blur="$v.accountForm.RoutingNumber.$touch()"
                />
              </template>
            </BaseFormGroup>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="5">{{ $t('content.account_number') }}</v-col>
          <v-col md="7">
            <BaseFormGroup name="Account Number" :validator="$v.accountForm.AccountNumber">
              <template slot-scope="{ attrs }">
                <v-text-field
                  v-bind="attrs"
                  outlined
                  dense
                  v-model="accountForm.AccountNumber"
                  :placeholder="`${$t('content.account_number')}...`"
                  single-line
                  class="rounded-sm  mt-2"
                  clearable
                  background-color="#fff"
                  @blur="$v.accountForm.AccountNumber.$touch()"
                ></v-text-field>
              </template>
            </BaseFormGroup>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="5">{{ $t('content.bank_name') }}</v-col>
          <v-col md="7">
            <BaseFormGroup name="Bank Name" :validator="$v.accountForm.BankName">
              <template slot-scope="{ attrs }">
                <v-text-field
                  v-bind="attrs"
                  outlined
                  dense
                  v-model="accountForm.BankName"
                  :placeholder="`${$t('content.bank_name')}...`"
                  single-line
                  class="rounded-sm  mt-2"
                  clearable
                  background-color="#fff"
                  @blur="$v.accountForm.BankName.$touch()"
                />
              </template>
            </BaseFormGroup>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="5"><div>{{ $t('content.checking_account') }}</div><div>{{ $t('content.uncheck_for_savings') }}</div></v-col>
          <v-col md="7">
            <v-checkbox
              outlined
              dense
              v-model="accountForm.IsChecking"
              single-line
              class="rounded-sm  mt-2"
              :hide-details="true"
              clearable
              background-color="#fff"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-btn
            small
            depressed
            class="orange white--text mr-md-3 mt-3"
            :block="$vuetify.breakpoint.smAndDown"
            :disabled="$v.accountForm.$invalid"
            @click="submitAccount()"
          >
            {{ $t("form.submit") }}
          </v-btn>
        </v-row>
      </div>
    </form>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { required, numeric, minLength, maxLength } from "vuelidate/lib/validators";

export default{
  name: 'BankAccountForm',
  validations: function() {
    return{
      accountForm:{
        RoutingNumber:{
          required,
          numeric,
          minLength:minLength(9),
          maxLength:maxLength(9)
        },
        AccountNumber:{
          required,
          numeric,
          maxLength:maxLength(17)
        },
        BankName:{ required }
      }
    }
  },
  data(){
    return{
      accountForm:{
        RoutingNumber:"",
        AccountNumber:"",
        BankName:"",
        IsChecking:true,
        IsDefault:true,
        RequiresValidation: false,
        CustomerId:""
      },
    }
  },
  computed:{
    ...mapGetters({
      paysimpleInfo: "Invoice/paysimpleInfo",
    }),
  },
  methods:{
    submitAccount(){
      this.$v.$touch();
      if (this.$v.accountForm.$invalid) {
        return;
      }
      var paysimple_id = JSON.parse(JSON.stringify(this.paysimpleInfo))[0].paysimple_id;
      this.accountForm.CustomerId = paysimple_id
      this.$store
        .dispatch("Invoice/postBankData", this.accountForm)
        .then(()=>{
          location.reload();
        })
        .catch(error => this.$store.dispatch("exceptionHandler/error", error));
    },
  },
}
</script>
<style>
.new-account-form>div{
  width:75%;
  border-radius:5px;
  background-color:white;
  padding:50px;
  margin:0px auto;
  margin-top:25px;
  box-shadow:0 2px 10px rgb(0 0 0 / 30%);
}
.new-account-form h3{
  background-color:#71abdb;
  color:white;
  margin:-50px;
  margin-bottom:40px;
  padding:20px;
}
</style>
