<template>

  <div>
    <form id="card-form" class = "new-account-form" v-on:submit.prevent="submitCard">
        <div class="t-navy">
          <h3>{{ $t('content.new_credit_card') }}</h3>
            <v-row>
              <v-col md="5">{{ $t('content.card_number') }}</v-col>
              <v-col md="7">
                <BaseFormGroup name="Card Number" :validator="$v.newCardForm.CreditCardNumber">
                  <template slot-scope="{ attrs }">
                    <v-text-field
                      v-bind="attrs"
                      outlined
                      dense
                      v-model="newCardForm.CreditCardNumber"
                      :placeholder="`${$t('content.card_number')}...`"
                      single-line
                      class="rounded-sm  mt-2"
                      clearable
                      background-color="#fff"
                      @blur="$v.newCardForm.CreditCardNumber.$touch()"
                    ></v-text-field>
                  </template>
                </BaseFormGroup>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="5">{{ $t('content.expiration_date') }}</v-col>
              <v-col md="3">
                <v-select
                  outlined
                  dense
                  v-model="newCardForm.month"
                  single-line
                  :items="months"
                  item-text="name"
                  item-value="id"
                  hide-details
                  background-color="#fff"
                  class="rounded-sm mr-3"
                />
              </v-col>
              <v-col md="4">
                <v-select
                  outlined
                  dense
                  v-model="newCardForm.year"
                  single-line
                  :items="years"
                  item-text="name"
                  item-value="id"
                  hide-details
                  background-color="#fff"
                  class="rounded-sm mr-3"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="5">{{ $t('content.issuer') }}</v-col>
              <v-col md="7">
                <v-select
                  outlined
                  dense
                  v-model="newCardForm.Issuer"
                  single-line
                  :items="card_options"
                  item-text="name"
                  item-value="id"
                  hide-details
                  background-color="#fff"
                  class="rounded-sm mr-3"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="5">{{ $t('content.zip_code') }}</v-col>
              <v-col md="7">
                <BaseFormGroup name="Zip Code" :validator="$v.newCardForm.BillingZipCode">
                  <template slot-scope="{ attrs }">
                    <v-text-field
                      v-bind="attrs"
                      outlined
                      dense
                      v-model="newCardForm.BillingZipCode"
                      :placeholder="`${$t('content.zip_code')}...`"
                      single-line
                      class="rounded-sm  mt-2"
                      clearable
                      background-color="#fff"
                      @blur="$v.newCardForm.BillingZipCode.$touch()"
                    ></v-text-field>
                  </template>
                </BaseFormGroup>
              </v-col>
            </v-row>
            <v-row>
              <v-btn
                small
                depressed
                class="orange white--text mr-md-3 mt-3"
                :block="$vuetify.breakpoint.smAndDown"
                :disabled='$v.newCardForm.$invalid'
                @click="submitCard()"
              >
              {{ $t("form.submit") }}
            </v-btn>
          </v-row>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required, numeric, minLength, maxLength } from "vuelidate/lib/validators";

export default{
  name: 'CardAccountForm',
  validations: function() {
    return{
      newCardForm:{
        CreditCardNumber:{
          required,
          numeric,
          maxLength:maxLength(16),
          minLength:minLength(15)
        },
        BillingZipCode:{
          required
        }
      },
    }
  },
  data(){
    return{
      newCardForm:{
        CreditCardNumber:"",
        month:"01",
        year:"2022",
        Issuer:"Visa",
        BillingZipCode:"",
        CustomerId:"",
        IsDefault:true,
      },
    }
  },
  computed:{
    ...mapGetters({
      paysimpleInfo: "Invoice/paysimpleInfo",
    }),
    card_options(){
        return["Visa", "Master", "Amex", "Discover"]
    },
    months(){
      var returnList = [];
      for(var a=1;a<13;a++){
        if(a<10)
          returnList.push("0"+a);
        else
          returnList.push(""+a);
      }
      return returnList
    },
    years(){
      var returnList = [];
      var first_year = new Date().getFullYear()
      for(var a=first_year;a<first_year+8;a++){
        returnList.push(""+a);
      }
      return returnList;
    }
  },
  methods:{
    submitCard(){
      var paysimple_id = JSON.parse(JSON.stringify(this.paysimpleInfo))[0].paysimple_id;
      this.newCardForm.CustomerId = paysimple_id;
      this.newCardForm.ExpirationDate=this.newCardForm.month+"/"+this.newCardForm.year;
      this.$store
        .dispatch("Invoice/postCardData", this.newCardForm)
        .then(()=>{
          location.reload();
          this.payForm.selectAccount=this.paysimpleInfo[0]
        })
        .catch(error => this.$store.dispatch("exceptionHandler/error", error));
    },
  },
}
</script>
<style>
#payment-form{
  width:65%;
  border-radius:5px;
  background-color:white;
  padding:50px;
  margin:0px auto;
  box-shadow:0 2px 10px rgb(0 0 0 / 30%);
}
#payment-form>h1{
  background-color:#71abdb;
  color:white;
  padding:15px;
  margin:-50px;
  margin-bottom:40px;
}
</style>
