<template>
  <div>
    <v-breadcrumbs :items="breadcrumb" divider=">" class="pl-0 pt-0" />
    <v-card>
      <v-row no-gutters>
        <v-col cols="12" md="4">
          <div class="d-flex align-center justify-center h-100 gradient">
            <svg-icon icon="change-password-background" class="pa-4" />
          </div>
        </v-col>
        <v-col cols="12" md="8">
          <div class="pa-4 pa-md-6">
            <form>
              <v-row no-gutters>
                <v-col cols="12" class="pb-0">
                  <BaseFormGroup :name="$t('form.current_password')" :validator="$v.form.current_password" noGutter>
                    <template slot-scope="{ attrs }">
                      <BaseLabel :title="$t('form.current_password')" />
                      <v-text-field
                        v-model="form.current_password"
                        v-bind="attrs"
                        :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showCurrentPassword ? 'text' : 'password'"
                        outlined
                        dense
                        class="rounded-lg"
                        counter
                        hide-details="auto"
                        :placeholder="$t('form.input_current_password')"
                        @input="$v.form.current_password.$touch()"
                        @blur="$v.form.current_password.$touch()"
                        @click:append="showCurrentPassword = !showCurrentPassword"
                      />
                    </template>
                  </BaseFormGroup>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <BaseFormGroup :name="$t('form.new_password')" :validator="$v.form.new_password" noGutter>
                    <template slot-scope="{ attrs }">
                      <BaseLabel :title="$t('form.new_password')" />
                      <v-text-field
                        v-model="form.new_password"
                        v-bind="attrs"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        outlined
                        dense
                        class="rounded-lg"
                        hint="At least 8 characters"
                        counter
                        hide-details="auto"
                        :placeholder="$t('form.enter_new_password')"
                        @input="$v.form.new_password.$touch()"
                        @blur="$v.form.new_password.$touch()"
                        @click:append="showPassword = !showPassword"
                      />
                    </template>
                  </BaseFormGroup>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <BaseFormGroup :name="$t('form.re_new_password')" :validator="$v.form.confirm_new_password" noGutter>
                    <template slot-scope="{ attrs }">
                      <BaseLabel :title="$t('form.re_new_password_upr')" />
                      <v-text-field
                        v-model="form.confirm_new_password"
                        v-bind="attrs"
                        :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showConfirmPassword ? 'text' : 'password'"
                        outlined
                        dense
                        class="rounded-lg"
                        hint="At least 8 characters"
                        counter
                        hide-details="auto"
                        :placeholder="$t('form.re_new_password')"
                        @input="$v.form.confirm_new_password.$touch()"
                        @blur="$v.form.confirm_new_password.$touch()"
                        @click:append="showConfirmPassword = !showConfirmPassword"
                      />
                    </template>
                  </BaseFormGroup>
                </v-col>
              </v-row>
            </form>
            <div class="mt-5">
              <v-btn
                :disabled="!this.$v.form.$dirty"
                class="mr-1 indigo white--text no-transform"
                @click="submit"
                :loading="buttonLoading"
              >
                {{ $t('signUpContent.change_password_title') }}
              </v-btn>
            </div>
          </div>
          <template>
            <v-dialog persistent v-model="showAuthDialog" max-width="500">
              <v-card>
                <div class="pt-10">
                  <v-img src="../../assets/images/success-icon.png" class="dialog-image" />
                </div>
                <div class="text-center text-h5 font-weight-bold pt-3 dialog-title">
                  {{ $t('form.new_password_top') }}
                </div>
                <div class="text-center pb-8 pt-2 dialog-body">
                  {{ $t('form.change_account_setting_password') }}
                </div>
                <div class="text-center pb-8">
                  <v-btn color="primary" class="dialog-button" @click="goToSetting">
                    {{ $t('content.ok') }}
                  </v-btn>
                </div>
              </v-card>
            </v-dialog>
          </template>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import password from '@/validators/password';

import { not, required, minLength, sameAs } from 'vuelidate/lib/validators';

export default {
  name: 'AuthChangePassword',
  validations: {
    form: {
      current_password: {
        required,
      },
      new_password: {
        required,
        minLength: minLength(8),
        password,
        isNotSameAsNewPassword: not(sameAs('current_password')),
      },
      confirm_new_password: {
        required,
        sameAsPassword: sameAs('new_password'),
      },
    },
  },
  data() {
    return {
      form: {
        current_password: '',
        new_password: '',
        confirm_new_password: '',
      },
      showCurrentPassword: false,
      showPassword: false,
      showConfirmPassword: false,
      showAuthDialog: false,
      buttonLoading: false,
    };
  },
  computed: {
    breadcrumb() {
      return [
        {
          text: this.$t('account.account_setting'),
          to: { name: 'AccountSetting' },
        },
        {
          text: this.$t('title.change_password'),
          disabled: true,
        },
      ];
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.form.$invalid) {
        return;
      } else {
        this.buttonLoading = true;
        this.$store
          .dispatch('Auth/changePassword', this.form)
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.showAuthDialog = true;
          })
          .catch(err => {
            if (err == 'Error: Invalid password.') {
              this.$toast.error('Wrong password');
            } else {
              this.$toast.error(err.message);
            }
          })
          .finally(() => {
            this.buttonLoading = false;
          });
      }
    },
    goToSetting() {
      this.$router.push({ name: 'AccountSetting' }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.gradient {
  background: rgb(0, 96, 175);
  background: linear-gradient(
    0deg,
    rgba(0, 96, 175, 0.6001751042213761) 0%,
    rgba(176, 210, 237, 0.569362779291404) 35%,
    rgba(255, 255, 255, 1) 100%
  );
}
.dialog-image {
  margin-left: auto;
  margin-right: auto;
  width: 120px;
}
.dialog-title {
  color: #16325c;
}
.dialog-body {
  font-size: 15px;
  color: #16325ccc;
}
.dialog-button {
  width: 100px;
  text-transform: none;
}
.no-transform {
  text-transform: none;
}
</style>
