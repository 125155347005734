<template>
  <div>
    <v-breadcrumbs :items="breadcrumb" divider=">" class="pl-0 pt-0" />
    <v-card>
      <v-row no-gutters>
        <v-col cols="12" md="4">
          <div class="d-flex align-center justify-center h-100 gradient">
            <svg-icon icon="change-password-background" class="pa-4" />
          </div>
        </v-col>
        <v-col cols="12" md="1"></v-col>
        <v-col cols="12" md="6" class='mt-8'>
          <v-row>
            <v-col cols="12" md="1"></v-col>
            <v-col cols="12" md="7">Payment Method</v-col>
            <v-col cols="12" md="2">Delete</v-col>
            <v-col cols="12" md="2">Make Default</v-col>
          </v-row>
          <v-row v-for="(item, index) in paySimpleAccounts[0].card_info" :key='index' class="card-row mt-8">
            <v-col cols="12" md="1"></v-col>
            <v-col cols="12" md="7">{{createDisplayName(item)}}</v-col>
            <v-col cols="12" md="2">
              <BaseActionButton tooltipsText="Delete" @click="deleteAccount(item.Id)">
                <SvgIcon icon="icon-delete" width="20px" height="20px" />
              </BaseActionButton>
            </v-col>
            <v-col cols="12" md="2">
              <BaseActionButton tooltipsText="Make Default" @click="makeDefault(item.Id)">
                <SvgIcon icon="icon-edit" width="20px" height="20px" />
              </BaseActionButton>
            </v-col>
          </v-row>
          <br />
        </v-col>
        <v-col cols="12" md="1"></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="5">
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            outlined
            dense
            v-model="selectedAdd"
            single-line
            :items="addAccount"
            hide-details
            background-color="#fff"
            class="rounded-sm mr-3"
          />
          <BankAccountForm v-if="selectedAdd=='Add ACH'" />
          <CardAccountForm v-if="selectedAdd=='Add Card'" />
        </v-col>
        <v-col cols="12" md="1"></v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BankAccountForm from '@/components/invoice/BankAccountForm';
import CardAccountForm from '@/components/invoice/CardAccountForm';

export default {
  name: 'PaymentMethods',
  components:{
    BankAccountForm,
    CardAccountForm
  },
  data() {
    return {
      paySimpleAccounts:[{}],
      selectedAdd:"Click to add account",
      addAccount:[
        "Click to add account",
        "Add ACH",
        "Add Card"
      ]
    }
  },
  computed: {
      ...mapGetters({
        paysimpleInfo: "Invoice/paysimpleInfo",
      }),
    breadcrumb() {
      return [
        {
          text: this.$t('account.account_setting'),
          to: { name: 'AccountSetting' },
        },
        {
          text: "Payment Methods",
          disabled: true,
        },
      ];
    },
  },
  methods: {
    fetchPaysimple() {
      this.$store
        .dispatch("Invoice/getPaysimpleInfo")
        .then(data => {
          this.paySimpleAccounts = data;
          console.log(this.paySimpleAccounts[0].card_info)
        })
        .catch(err => this.$toast.error(err.message));
    },
    goToSetting() {
      this.$router.push({ name: 'AccountSetting' }).catch(() => {});
    },
    createDisplayName(item){
      var isDefault = (item.IsDefault)?" (Default)":"";
      if("CreditCardNumber" in item){
        return item.Issuer+" "+item.CreditCardNumber.slice(-6)+isDefault;
      }
      return item.BankName+" "+item.AccountNumber.slice(-6)+isDefault;
    },
    deleteAccount(id){
      this.$store
        .dispatch("Invoice/deleteAccount", id)
        .then(()=>{
          this.fetchPaysimple()
        })
        .catch(error => this.$store.dispatch("exceptionHandler/error", error));
    },
    makeDefault(id){
      this.$store
        .dispatch("Invoice/makeDefault", id)
        .then(()=>{
          this.fetchPaysimple()
        })
        .catch(error => this.$store.dispatch("exceptionHandler/error", error));
    },
  },
  created() {
    this.fetchPaysimple();
  }
};
</script>

<style lang="scss" scoped>
.gradient {
  background: rgb(0, 96, 175);
  background: linear-gradient(
    0deg,
    rgba(0, 96, 175, 0.6001751042213761) 0%,
    rgba(176, 210, 237, 0.569362779291404) 35%,
    rgba(255, 255, 255, 1) 100%
  );
}
.dialog-image {
  margin-left: auto;
  margin-right: auto;
  width: 120px;
}
.dialog-title {
  color: #16325c;
}
.dialog-body {
  font-size: 15px;
  color: #16325ccc;
}
.dialog-button {
  width: 100px;
  text-transform: none;
}
.no-transform {
  text-transform: none;
}
.card-row{
  color:rgba(22,50,92,0.8);
  border-radius:10px;
  font-size:15px;
  background-color:#f2f7fb;
}
</style>
